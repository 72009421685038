.card {
    padding: $size-20;
    background-color: $color-neutral-white;
    border-radius: 8px;
    box-shadow: $shadow;

    &--np {
        padding: 0;
    }
}
  
.card--responsive {
    @include mobileWrapperPadding();
    //margin-left: -#{$padding-wrapper-mobile}; // for .wrapper
    //margin-right: -#{$padding-wrapper-mobile}; // for .wrapper
    //padding: $padding-wrapper-mobile;
    background-color: $color-neutral-white;

    @include tablet() {
        padding: $size-20;
        margin: 0;
        border-radius: 8px;;    
        box-shadow: 2px 2px 10px 0 rgba(0,0,0,.1);
    }
}

.card .card__header {
    display: flex;
    background-image: linear-gradient(to bottom, #fff, #d9faf3);
    min-height: 400px;
    object-fit: contain;
    justify-content: center;
    align-items: center;
}

.card .card__header img {
    width: auto;
}

.card .card__content {
    padding: $size-20;
}
  