/*
Override some of normalize.css and browser style rules
*/ 

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// *:focus {
// 	outline: none;
// }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

html {
	position: relative;
	min-height: 100%;
	overflow-y: scroll;
}

body {
	margin: 0;
	padding: 0;
	line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  width: 100%;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

a {
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
    list-style-type: none;
}

li {
  list-style: none;
}

/* override normalize.css */
figure {
	margin: 0;
}

/*
 * Remove default fieldset styles.
 */
 fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}