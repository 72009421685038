
$color-primary-400: hsl(165, 46%, 50%);       // #44B99B
$color-secondary-400: hwb(164 23% 31%);     // #3bb292
$color-tertiary-400: #68dac4;
$color-accent-400: hsl(342, 62%, 50%);        // #cf3061   
$color-neutral-onyx: hsl(0, 0%, 13%);         // #212121
$color-neutral-pearl: hsl(0, 0%, 13%);        // #5c5c5c
$color-neutral-white: hsl(0, 0%, 100%);       // #ffffff
$color-neutral-sand: hsl(39, 13%, 69%);       // #bbb4a7
$color-neutral-gray: hsl(0, 0%, 85%);         // #d8d8d8
$color-neutral-dc: hsl(40, 11%, 16%);         // #2e2b25 - Dark Chocolate
$color-neutral-wood: hsl(39, 11%, 30%);       // #554f44 - Wood shade
$color-neutral-linen: hsl(38, 13%, 71%);      // #beb7ab - Deprecated / replace by sand

// For utilities loop in _utilities.scss
$colors:(
  neutral: (
    onyx: $color-neutral-onyx,
    pearl: $color-neutral-pearl,        
    white: $color-neutral-white,       
    sand: $color-neutral-sand,       
    gray: $color-neutral-gray,         
    dc: $color-neutral-dc,          
    wood: $color-neutral-wood,   
    linen: $color-neutral-linen,   
  ),
  primary: (
    400: $color-primary-400,
  ),
  secondary: ( 
    400: $color-secondary-400,
  ),
  accent: (
    400: $color-accent-400,
  ),
);