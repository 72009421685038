
$button-border-radius: 100px;
$button-padding: 1rem 2rem;
$button-border: 1px;
$icon-margin: .7rem;

.btn {
    display: inline-flex;
    padding: $button-padding;
    justify-content: center;
    align-items: center;
    gap: .6rem;
    border: 0;
    border-radius: $button-border-radius;
    font-size: .8rem;
    font-weight: $fw-700;
    font-family: $ff-primary;
    color: $color-neutral-white;
    text-transform: uppercase;
    width: auto;
    transition: all .08s linear;
    white-space: nowrap;
    cursor: pointer;

    /**
     * - PRIMARY
     */
    &.btn--primary {
        background-color: #3BB292;  
        background-image: linear-gradient(270deg, rgba(109,217,196,1), rgba(109,217,196,0));;
        box-shadow: 0 4px 10px 0 rgba(0, 152, 111, 0.5);

        &:hover {
            box-shadow: none;
            background-image: none;
            background-color: $color-secondary-400;            
        }
    }

    &.btn--outlined {
      background-color: transparent;
      border: 1px solid $color-neutral-sand !important;
      color: $color-neutral-dc !important;
      font-weight: $fw-700;
      transition: none;

      &:hover {
        border-color: $color-primary-400 !important;
        color: $color-primary-400 !important;
      }
      &.btn--outlined-ondark:hover {
        border-color: $color-neutral-white !important;
        color: $color-neutral-white !important;
      }
    }


    /**
     * - TRANSPARENT ON DARK
     */
    &.btn--transparent-on-dark {
        background-image: none;
        background-color: transparent;
        border: $button-border solid $color-neutral-white;;

        &:hover {
            border-color: $color-primary-400;
            color: $color-primary-400;
          }
    }

    /**
     * - TRANSPARENT ON LIGHT
     */
     &.btn--transparent-on-light,
     &.btn--tol {
        background-image: none;
        background-color: transparent;
        border: $button-border solid $color-neutral-gray;
        color: $color-neutral-gray;
        
        &:hover {
            border-color: $color-neutral-white;
            color: $color-neutral-white;
        }
    }

    &.btn--auth { 
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      border: none;
      color: $color-neutral-dc;
      justify-content: start;

      
      &.active,
      &:hover {
      
        color: $color-primary-400;
        
        @include desktop {
          background-color: hwb(164 23% 31%);
          color: $color-neutral-white;
        }

        .icon {
          background-color: $color-neutral-white;
        }
       
      }

      .icon {
        background-color: $color-neutral-dc;
      }
    }


    /**
     * - DESTRUCTIVE
     */
    &.btn--destructive {
        border: $button-border solid $color-neutral-gray;
        color: $color-neutral-gray;

        &:hover {
            border-color: $color-accent-400;
            color: $color-accent-400;
        }
    }

    // - No border
    &.btn--nb { 
        border: none;
    }

    &.btn--l {
      font-size: .92rem;
    }

    &.btn--sm {
        @include tablet {
          padding: 0 1.33rem !important;
          font-size: .72rem;
          font-weight: 700;
          line-height: 2.22rem !important;
        }
    }



    // - Small padding
    &.btn--sp {
        padding:0 1.33rem 0 .5rem !important;
        font-size: .72rem;
        line-height: 2.22rem !important;
    }

    &:disabled {
        background-image: none;
        background-color: $color-neutral-gray;
        box-shadow: none;
        color: $color-neutral-white;
        border: none;
        cursor: not-allowed;
    }
    
    &.btn--icon-rounded {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 2px;
      background-color: #3BB292;  
      color: white;
    }
    
}


/**
 * spinner intead of text on loading
 * this class is added dynamically by ypLoading directive
 */
 .btn--loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;
    cursor: not-allowed;
  
    &:hover, &:focus {
      color: transparent;
    }
  
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      width: 20px;
      height: 20px;
      animation: spinner .75s linear infinite;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1.45px solid #fff;
      // border-top-color: $primary;
      content: '';
    }
  }
  
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

/* ICON POSITION */
.btn > i:first-child {
    // margin-right: $icon-margin;
}

/* ICON POSITION */
.btn > i:last-child {
   //  margin-left: $icon-margin;
}

