$sizes: (
  8: 0.5rem,
  12: 0.75rem,
  16: 1rem,
  20: 1.25rem,
  24: 1.5rem,
  32: 2rem,
  36: 2.25rem,
);

// Default pixel size is 16px (on desktop)
$size-100: 6.25rem;
$size-80: 5rem;
$size-64: 4rem;
$size-60: 3.75rem;
$size-50: 3.125rem;
$size-48: 3rem;
$size-46: 2.875rem;
$size-44: 2.75rem;
$size-42: 2.625rem;
$size-40: 2.5rem;
$size-38: 2.375rem;
$size-36: 2.25rem;
$size-34: 2.125rem;
$size-32: 2rem;
$size-30: 1.875rem;
$size-28: 1.75rem;
$size-26: 1.625rem;
$size-24: 1.5rem;
$size-22: 1.375rem;
$size-20: 1.25rem;
$size-19: 1.1875rem;
$size-18: 1.125rem;
$size-17: 1.0625rem;
$size-16: 1rem;
$size-15: .9375rem;
$size-14: .875rem;
$size-13: .8125rem;
$size-12: .75rem;
$size-11: .6875rem;
$size-10: .625rem;
$size-8: .5rem;
$size-7: .4375rem;
$size-6: .375rem;
$size-5: .3125rem;
$size-4: .25rem;
$size-2: .125rem;


