$prefix: "ex-";

$cdn-new: 'https://d2xec21l9srv8z.cloudfront.net/v3/';
$cdn: 'https://d2xec21l9srv8z.cloudfront.net';
$cdn-icons: $cdn-new + 'icons/';
$cdn-pictos: $cdn-new + 'picto/';
$cdn-flags: $cdn-new + 'flags/';
$cdn-bg: $cdn-new + 'backgrounds/';

$navbar-height: 5.55rem;
$shadow: 0 3px 16px 0 rgba(46, 43, 37, 0.2);
// $border-radius-1: $size-2;
// $border-radius-2: $size-4;

// $box-shadow-1: 0 $size-1 $size-4 rgb(0 0 0 / 0.05);
// $box-shadow-2: 0 $size-1 $size-4 rgb(0 0 0 / 0.15);

// - BODY
// $body-font-family: $ff-primary;
// $body-font-size: $font-size-400;
// $body-line-height: 1.5;
// $body-font-weight: $font-weight-default;
// $body-text-color: $color-text-default;
// $body-background-color: $color-background-default;

// - FORM

// - BUTTON

// - NAVIGATION

// - CARD

// - FOOTER