/**************************************

YUMMYPETS EXPLORER

Symrise © 2023
Authors : Jérémy Junker

**************************************/

@charset "UTF-8";

// - [CORE] Base 
@import "./assets/style-core/base/reset.scss";

// - [BULMA]
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns.sass";

// - [CORE] Abstracts 
@import "./assets/style-core/abstracts/mixins-core.scss";
@import "./assets/style-core/abstracts/breakpoints.scss";

// - [CORE] Utilities 
@import "./assets/style-core/utilities/flex.scss";
@import "./assets/style-core/utilities/grid.scss";
@import "./assets/style-core/utilities/helpers.scss";
@import "./assets/style-core/utilities/space.scss";
@import "./assets/style-core/utilities/grid-auto-fit.scss";

// - [EXPLORER] Abstracts
@import "./assets/style/abstracts/variables.scss";
@import "./assets/style/abstracts/sizes.scss";
@import "./assets/style/abstracts/mixins.scss";
@import "./assets/style/abstracts/colors.scss";
@import "./assets/style/abstracts/typography.scss";

// - [EXPLORER] Base
@import "./assets/style/base/root.scss";
@import "./assets/style/base/layout.scss";	

// - [EXPLORER] Elements
@import "./assets/style/elements/icons.import.scss";
@import "./assets/style/elements/button.scss";
@import "./assets/style/elements/link.scss";
@import "./assets/style/elements/radio.scss";
@import "./assets/style/elements/checkbox.scss";
@import "./assets/style/elements/forms.scss";
@import "./assets/style/elements/spinner.scss";
@import "./assets/style/elements/card.scss";

// - [EXPLORER] Utilities	
@import "./assets/style/utilities/";

// - [EXPLORER] Material
@import "./assets/style/material/theme.scss";
@import "./assets/style/material/input.scss";
@import "./assets/style/material/select.scss";
@import "./assets/style/material/radio.scss";
@import "./assets/style/material/stepper.scss";
@import "./assets/style/material/form.scss";
@import "./assets/style/material/toggle.scss";
@import "./assets/style/material/dialog.scss";	
@import "./assets/style/material/chip.scss";

/* COLORS
	- default body text  #ffffcc
	- subtitle h2 text   #ff9900
	- form input borders #cc9933
	- default p text     #f0f0f0
	- pre borders        #ffff99
*/

// INSPIRATION
// https://github.com/kevin-powell/website-starter-template-v3-prefixed/tree/main/sass

// CSS GUIDELINE
//  - https://cssguidelin.es/