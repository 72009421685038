//------------------------------------------------------
// - FORM
//------------------------------------------------------

.form__label {
    font-family:  $ff-primary;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: -0.38px;
    color: $color-neutral-dc;
    text-align: left;
    @include tablet {
      font-size: 1.1rem;
    }
}

.form__required {
    font-weight: normal;
    color: #3bb292;  // TODO: use variable
}


//------------------------------------------------------
// - FORM SECTION
//------------------------------------------------------

.form-section__title {
    position: relative;
    margin: 20px 0 40px 0;
    font-family: $ff-primary;
    font-size:  1.75rem;
    font-weight: $fw-700;
    letter-spacing: -0.58px;
    color: $color-neutral-dc;
    overflow: hidden;
    @include tablet {
      font-size:  1.875rem;
     
    }
}

.form-section__title:after {
    background-color: #3bb292; // TODO: use variable
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    left: 0.5em;
    margin-right: -100%;
  }

