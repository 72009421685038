// /* Style des boutons radio */
// .radio-button input[type="radio"] {
//     display: none; /* Cache le bouton radio par défaut */
//   }
  
//   /* Style des labels des boutons radio */
//   .radio-button label {
//     display: inline-block;
//     cursor: pointer;
//     padding-left: 25px; /* Espace pour le bouton radio personnalisé */
//     position: relative;
//     margin-right: 10px; /* Espacement entre les boutons */
//   }
  
//   /* Style du bouton radio personnalisé */
//   .radio-button label:before {
//     content: '';
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     border: 2px solid #ccc;
//     border-radius: 50%; /* Rend le bouton radio rond */
//     position: absolute;
//     left: 0;
//     top: 1px;
//   }
  
//   /* Style du bouton radio coché */
//   .radio-button input[type="radio"]:checked + label:before {
//     background-color: #007bff; /* Change la couleur de fond lorsque le bouton radio est coché */
//   }
  
//   /* Style du point central du bouton radio coché */
//   .radio-button input[type="radio"]:checked + label:after {
//     content: '';
//     position: absolute;
//     top: 5px;
//     left: 5px;
//     width: 6px;
//     height: 6px;
//     background-color: #fff;
//     border-radius: 50%;
//     display: block;
//   }
  


//------------------------------------------------------
// - RADIO CUSTOM 
//------------------------------------------------------

.radio__switch {
    position: relative;
    display: flex;
    height: 48px;
    min-width: 327px;
    padding: 2px;
    border-radius: 24px;    
    border: solid 1px $color-neutral-sand; 
}

.radio__switch label {
    width: 50%;
    padding: 12px;
    text-align: center; 
    cursor: pointer;
    border-radius: 24px;
    font-size: .9rem;
    font-family: $ff-primary;
    font-weight: $fw-700;
    color: $color-neutral-wood;
    z-index: 1;
}

.radio__switch input[type=radio] + label {
    background-color: white;
    transition: background-color .1s ease;
}

.radio__switch.transparent input[type=radio] + label{
    background-color: transparent;
}

.radio__switch input[type=radio]:checked + label {
    background-color: $color-secondary-400;
    color: #fff;
    color: $color-neutral-white;
}   

.radio__switch input[type="radio"] {
    display: none;
}

//------------------------------------------------------
// - RADIO CUSTOM SVG
//------------------------------------------------------

.radio__group {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin: 20px 0;
    gap: 20px;
}

.radio__group--space {
    @include mobile {
        justify-content: space-between;
    }
}



//------------------------------------------------------
// - RADIO CUSTOM SVG CIRCLE
//------------------------------------------------------
  
.radio__circle {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-bottom: 10px;
    cursor: pointer;

    input[type=radio] {
        display: none;
    }
    
    input[type=radio]+label span {
        border: solid 3px transparent;
    }
}
   
.radio__circle p {
    text-align: center;
    margin-top: 10px;
    font-family: $ff-primary;
    font-size: .9rem;
    font-weight: 500; // TODO: use variable
    letter-spacing: -0.35px; 
    color: #554f44; // TODO: use variable
    overflow-wrap: break-word;
    @include tablet {
        font-size: 1rem;
    }
}

.radio__circle input[type=radio]:checked+label {
    span {
        border: solid 3px $color-secondary-400; // TODO: use variable
        fill: #3bb292;
        &::before {
            background-color: $color-secondary-400; // TODO: use variable
        }
    }

    p {
        color: $color-secondary-400; // TODO: use variable
    }
}


//------------------------------------------------------
// - CLASSE SHARED WITH CHECKBOX CIRCLE
//-----------------------------------------------------
.radio__img {
    display:block;
    position: relative;
    margin-bottom: .625rem;
    background-repeat: no-repeat;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border: solid 1px transparent;
    box-shadow: 0 3px 10px 0 rgba(46, 43, 37, 0.2);
    cursor: pointer;

    &.md {
        width: 50px;
        height: 50px;
    }

    &.active, 
    &.completed {
        border: solid 3px $color-secondary-400; // TODO: use variable
        fill: #3bb292;
        &::before {
            background-color: $color-secondary-400; // TODO: use variable
        }
        // border: solid 1px $color-primary-400;
        &.edit {
            fill: $color-neutral-gray;
        }
    }

    &.disabled {
        cursor: not-allowed;
        border: solid 3px $color-neutral-gray;
        fill:  $color-neutral-gray;;
    }

    &.error {
        border: solid 3px red;
        &::before {
            background-color: red;
        }
    }

  
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        z-index: 5;
        background: inherit;
        background-color: #554f44;
        mask-repeat: no-repeat;
        mask-position: center;
        // mask-size: contain;
    }

    // TODO generate using SASS

    // - LIVING
    &.living_house::before{mask-image: url(#{$cdn-pictos}picto_living_house.svg);}
    &.living_flat::before {mask-image: url(#{$cdn-pictos}picto_living_flat.svg);}
    &.living_other::before {mask-image: url(#{$cdn-pictos}picto_living_other.svg);}
  
    // - LOCATION
    &.location_big::before {mask-image: url(#{$cdn-pictos}picto_location_big.svg); }
    &.location_small::before {mask-image: url(#{$cdn-pictos}picto_location_small.svg);}
    &.location_countryside::before{mask-image: url(#{$cdn-pictos}picto_location_countryside.svg);}
  
    // - SHOPPING
    &.shopping_online::before {mask-image: url(#{$cdn-pictos}picto_shopping_place_online.svg); }
    &.shopping_petshop::before {mask-image: url(#{$cdn-pictos}picto_shopping_place_petshop.svg);}
    &.shopping_discounter::before {mask-image: url(#{$cdn-pictos}picto_shopping_place_discounter.svg);}
    &.shopping_hypermarket::before {mask-image: url(#{$cdn-pictos}picto_shopping_place_hypermarket.svg);}
    &.shopping_vet::before {mask-image: url(#{$cdn-pictos}picto_shopping_place_vet.svg);}
    &.groomer::before, &._105::before{mask-image: url(#{$cdn-pictos}picto_shopping_place_groomers.svg);}
    &.shopping_other::before, &._106::before{mask-image: url(#{$cdn-pictos}picto_shopping_place_other.svg);}
  
    // - FOOD
    &.food_dry::before {mask-image: url(#{$cdn-pictos}picto_food_type_dry.svg); }
    &.food_wet::before {mask-image: url(#{$cdn-pictos}picto_food_type_wet.svg);}
    &.food_dehydrated::before {mask-image: url(#{$cdn-pictos}picto_food_type_dehydrated.svg);}
    &.food_treats::before {mask-image: url(#{$cdn-pictos}picto_food_type_treats.svg);}
    &.food_raw::before {mask-image: url(#{$cdn-pictos}picto_food_type_raw.svg);}
    &.food_homemade::before {mask-image: url(#{$cdn-pictos}picto_food_type_homemade.svg);}
    &.food_other::before {mask-image: url(#{$cdn-pictos}picto_food_type_foodother.svg);}
    &.food_table::before {mask-image: url(#{$cdn-pictos}picto_food_type_table.svg);}
    &.food_dried::before {mask-image: url(#{$cdn-pictos}picto_food_type_dried.svg);}
    &.food_frozen::before {mask-image: url(#{$cdn-pictos}picto_food_type_frozen.svg);}
    &.food_supplements::before {mask-image: url(#{$cdn-pictos}picto_food_type_supplements.svg);}
  
    // - PETS
    &.dog::before, &._1::before{mask-image: url(#{$cdn-pictos}picto_dog.svg);}
    &.cat::before, &._2::before{mask-image: url(#{$cdn-pictos}picto_cat.svg);}
    &._6::before,
    &._7::before,
    &._8::before,
    &._9::before,
    &._10::before,
    &._12::before,
    &._13::before {mask-image: url(#{$cdn-pictos}picto_other.svg);}
}