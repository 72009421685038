//------------------------------------------------------
// - CHECKBOX
//------------------------------------------------------

mat-mdc-list-option .mdc-checkbox {
    border-radius: 4px !important;
    border: solid 3px #bbb4a7 !important;
    width: 16px !important;
    height: 16px !important;
  
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: white !important;
    border: 3px solid #3bb292!important;
}

// .mdc-checkbox {
//     border-radius: 4px !important;
//     border: solid 3px #bbb4a7 !important;
//     width: 16px !important;
//     height: 16px !important;
//     margin-right: 15px;
  
//     &.mdc-checkbox-checked {
//       background-color: white !important;
//       border: 3px solid #3bb292!important;
//       padding: 2px;
//     }
// }

// .mdc-checkbox::after {
//     color: #3bb292 !important;
//     top: 2px !important;
//     left: 1px !important;
//     width: 6px !important;
//     height: 3px !important;
// }
  

//------------------------------------------------------
// - MAT SELECTION LIST
//------------------------------------------------------

 .list-checkbox mat-selection-list {
    display: flex;
    flex-wrap: wrap;

    .mat-mdc-list-item,
    .mat-mdc-list-option {
        width: auto;
        height: auto;
        padding: 2px;
        @include mobile {
           width: 28%;
        }
    }
  
    .mat-list-base {
      padding:2px 2px !important;
    }
  
    .mat-list-item-content-reverse {
      padding: 0 !important;
    }
  
    mat-list-option:hover {
      background: none;
    }

    .mdc-list-item__end {
        display: none !important;
    }

    mat-list-option .mdc-list-item__content {
      white-space: normal;
    }

    .mdc-list-item__primary-text {
      overflow: visible;
      overflow-wrap: break-word !important;
      white-space: normal;
    }

    .mat-pseudo-checkbox{
      display: none !important;
    }

    .mat-list-item-ripple {
      display: none !important;
    }

}

.list-checkbox__circle mat-selection-list:focus {
    outline: none;  
}
  
//------------------------------------------------------
// - MAT SELECTION LIST CIRCLE
//------------------------------------------------------

.list-checkbox--circle {

    mat-selection-list {
      justify-content: space-between;
      align-items: start;
      flex-wrap: wrap;
      @include tablet {
        gap: 20px;
        justify-content: start;
      }
    }
  
    .mdc-list-item__content {
      width: 100px;
      height: auto;
      margin-bottom: 10px;
      padding: 0 !important;
      overflow-wrap: break-word !important;
    }
  
    .mat-list-custom-avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      cursor: pointer;
    }
  
    p {
      // TODO SAME CONFIG FROM RADIO
      text-align: center;
      margin-top: 10px;
      font-family: $ff-primary;
      font-size: .9rem;
      font-weight: 500;
      letter-spacing: -0.35px;
      overflow-wrap: break-word;
      @include tablet {
        font-size: 1rem;
      }
    }
  
    mat-list-option[aria-selected="true"],
    mat-list-option.active {
  
      span.radio__img {
        box-shadow: 0 0 5px 0 rgba(59, 178, 146, 0.5);
        border: solid 3px #3bb292;
        fill: #3bb292;
        &::before {
          background-color: #3bb292
        }
      }
      p{
        color: #3bb292;
      }
    }
  }
  