@use '@angular/material' as mat;

@include mat.core();

/**
 * COLORS
 */ 

/* For use in src/lib/core/theming/_palette.scss */
$md-explorer: (
    50 : #e9f7f3,
    100 : #c7eae1,
    200 : #a2dccd,
    300 : #7cceb9,
    400 : #60c4aa,
    500 : #44b99b,
    600 : #3eb293,
    700 : #35aa89,
    800 : #2da27f,
    900 : #1f936d,
    A100 : #ceffee,
    A200 : #9bffdc,
    A400 : #68ffcb,
    A700 : #4effc2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$explorer-primary: mat.define-palette($md-explorer, 500);
$explorer-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$explorer-warn: mat.define-palette(mat.$red-palette);

/**
 * TYPOGRAPHY
 */
 $explorer-typography: mat.define-typography-config(
    $font-family: 'Figtree, Roboto, sans-serif',
  );
//   $display-4:     mat.define-typography-level($font-size: 96px, $font-weight: 300, $font-family: Rubik),
//   $display-3:     mat.define-typography-level($font-size: 60px, $font-weight: 500, $font-family: Rubik),
//   $display-2:     mat.define-typography-level($font-size: 48px, $font-weight: 500, $font-family: Rubik),
//   $display-1:     mat.define-typography-level($font-size: 34px, $font-weight: 500, $font-family: Rubik),
//   $headline:      mat.define-typography-level($font-size: 24px, $font-weight: 500, $font-family: Rubik),
//   $title:         mat.define-typography-level($font-size: 20px, $font-weight: 500, $font-family: Rubik),
//   $subheading-2:  mat.define-typography-level($font-size: 18px, $font-weight: 500, $font-family: Rubik),
//   $subheading-1:  mat.define-typography-level($font-size: 20px, $font-weight: 500, $font-family: Rubik),
//   $body-2:        mat.define-typography-level($font-size: 16px, $font-weight: 400, $font-family: Rubik),
//   $body-1:        mat.define-typography-level($font-size: 18px, $font-weight: 400, $font-family: Rubik),
//   $caption:       mat.define-typography-level($font-size: 16px, $font-weight: Medium, $font-family: Rubik),
//   $button:        mat.define-typography-level($font-size: 16px, $font-weight: 500, $font-family: Rubik),
//   $input:         mat.define-typography-level($font-size: inherit, $line-height: 1.125, $font-weight: 500, $font-family: Rubik),



$explorer-theme: mat.define-light-theme((
 color: (
   primary: $explorer-primary,
   accent: $explorer-accent,
   warn: $explorer-warn,
 ),
 typography: $explorer-typography,
 density: 0,
));

@include mat.all-component-themes($explorer-theme);
