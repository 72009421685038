.d-none {
    display: none !important;
  }
  
.d-inline-block {
    display: inline-block;
}

.d-block {
    display: block !important;
}

/* display-mobile-none: hidden on mobile */
.d-m-none {
    @include mobile {
        display: none !important;
    }
}

/*
position element to center vertically inside any parent
how to use:
div.parent-with-any-class
  div.self-vcenter
*/
.self-vcenter {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/*
center and block horizontally
the block should have a certain width (in rem or %)
*/
.self-hcenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.text--center {
    text-align: center !important;
}
  
.text--right {
    text-align: right !important;
}

.text--left {
    text-align: left !important;
}

.block--right {
    float: right;
}

.block--left {
    float: left;
}

/* DISABLING OVERFLOW */
.no-overflow {
    overflow: hidden;
}
  
/* TEXT FORMAT */
.uppercase {
	text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.underlined {
	text-decoration: underline;
}