$gutter: 1.25rem;
$half-gutter: ($gutter * 0.5);

.col {
    display: block;
    flex: 1 1 auto;
    padding: $half-gutter;
  
    &.col--narrow {
      flex: none;
    }
  
    @include tablet() {
      @for $i from 1 through 12 {
  
        &.col-t-#{$i} {
          flex: none;
          width: calc($i / 12) * 100%;
        }
  
        &.col--offset-#{$i} {
          margin-left: calc($i / 12) * 100%;
        }
      }
    }
  
    @include desktop() {
      @for $i from 1 through 12 {
  
        &.col-d-#{$i} {
          flex: none;
          width: calc($i / 12) * 100%;
        }
  
        &.col-d-offset-#{$i} {
          margin-left: calc($i / 12) * 100%;
        }
      }
    }
  }
  
  // same as tablet
  .cols.cols--mobile {
    @include mobile() {
      @for $i from 1 through 12 {
    
        .col-#{$i} {
          flex: none;
          width: calc($i / 12) * 100%;
        }
    
        .col--offset-#{$i} {
          margin-left: calc($i / 12) * 100%;
        }
      }
    }
  }
  
  
  .cols {
    margin-left: -$half-gutter;
    margin-right: -$half-gutter;
    margin-top: -$half-gutter;
  
    &:last-child {
      margin-bottom: -10px;
    }
  
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  
    &.cols--mobile {
      display: flex;
    }
  
    &.cols--multiline {
      flex-wrap: wrap;
    }
  
    @include tablet() {
      display: flex;
  
      // Modifiers
  
      &.cols--center {
        justify-content: center;
        align-items: center;
      }
  
      &.cols--hcenter {
        justify-content: center;
      }
  
      &.cols--vcenter {
        align-items: center;
      }
    }
  
    &.cols--gapless {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      &:not(:last-child) {
        margin-bottom: $gutter;
      }
  
      & > .col {
        margin: 0;
        padding: 0;
      }
    }
  }