$ff-figtree: "Figtree", Arial, Helvetica, sans-serif;
$ff-primary: $ff-figtree;

$font-sizes: (
  900: 5rem,
  800: 3.75rem,
  600: 1.6875rem,
  500: 1.3125rem,
  400: 1.125rem,
  300: 1rem,
);

/*------------------------------------
- FONT WEIGHT
-------------------------------------*/
$fw-400: 400; // - regular
$fw-500: 500; // - semibold
$fw-700: 700; // - bold


