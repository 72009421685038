// @use "../abstracts/" as *;

// desktop
// $fs-h1: 3rem;
// $fs-

:root {
    --fs-300: 0.8125rem;
    --fs-400: 0.875rem;
    --fs-500: 0.9375rem;
    --fs-600: 1rem;
    --fs-700: 1.875rem;
    --fs-800: 2.5rem;
    --fs-48: 3.5rem;
}

body {

  font-family: $ff-primary, 'sans-serif';
  font-weight: $fw-400;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  @include tablet() {
      font-size: 15px;
  }
  @include desktop() {
      font-size: 16px;
  }

//   font-size: $body-font-size;
//   background-color: $body-background-color;
//   line-height: $body-line-height;
}

h1 {
    font-size: 48px; // desktop
}

h2 {
    font-size: 38px; // desktop
}

h3 {
    font-size: 20px; // desktop
}

a {
  // color: $link-color;
}

a:is(:hover, :focus) {
  // color: $link-color-hover;
}