
// -------------------------------------------------------------------------
// Responsive breakpoints
// -------------------------------------------------------------------------
$large-mobile: 600px;
$tablet: 769px;
$desktop: 960px;
$widescreen: 1200px;

@mixin responsiveMin($minWidth) {
	@media only screen and (min-width: $minWidth) {
		@content;
  }
}

@mixin responsiveMax($maxWidth) {
	@media only screen and (max-width: $maxWidth) {
		@content;
  }
}

@mixin mobile() {
	@media screen and (max-width: calc($tablet - 1px)) {
    @content;
  }
}

@mixin tablet() {
	@media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-only() {
	@media screen and (min-width: $tablet) and (max-width: calc($desktop - 1px)) {
    @content;
  }
}

@mixin desktop() {
	@media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin retina() {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content
	}
}
