
/*
* Icons examples
* 
* [Base]    .icon 
* [Colors]  .i--primary, i--secondary
* [Sizes]   .i--sm, .i--md, .i--lg
* [Types icon]   .i--profile, .i--add, .i--dog ...
* [Types picto]  .i--picto-switch, .i--picto-edit ...
*
* Resources : 
* - https://medium.com/@victoriakronsell/3-different-approaches-to-creating-an-icon-library-using-svgs-1caae8aaee09
* - https://chenhuijing.com/blog/the-many-methods-for-using-svg-icons/#%F0%9F%8F%80
* - https://cloudfour.com/thinks/svg-icon-stress-test/
*/

.icon {
    display: inline-block;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;

    &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// - Icon path CDN
$icon-types: (
    add,    
    add_solid,
    dog,
    cat,
    question,
    check,
    3dots,
    profile, 
    people,
    password,
    circleyes,
    circleno,
    nature,
    clock,
    settings,
    survey,
    logout,
    shelter,
    _warning-circle // todo: rename warning-circle
);

// - Picto path CDN
$icon-types-picto: (
    cat, 
    dog,
    swith,
    edit
);


$icons-color: (
  primary: $color-primary-400,
  secondary:  $color-secondary-400,
  white: $color-neutral-white,
  onyx: $color-neutral-onyx,
  alert: $color-accent-400,
  transparent: transparent,
);

// $icon-colors-name:(primary, secondary, white, transparent, black);

$icons-size: (
    xxs: 10px,
    es: 16px,
    sm: 24px,
    md: 48px,
    l: 96px,
);

@mixin icon-type($type, $link) {
    mask-image: url(#{$cdn-icons}#{$link}#{$type}.svg);
    -webkit-mask-image: url(#{$cdn-icons}#{$link}#{$type}.svg);
}

@mixin icon-type-picto($type, $link) {
    mask-image: url(#{$cdn-pictos}#{$link}#{$type}.svg);
    -webkit-mask-image: url(#{$cdn-pictos}#{$link}#{$type}.svg);
}

@mixin icon-color($color) {
    background-color: map-get($icons-color, $color);
}

@mixin icon-size($size) {
    width: map-get($icons-size, $size) !important;
    height: map-get($icons-size, $size) !important;
}

@mixin build-icon($type, $color, $size) {
    @include icon-type($type, 'icon_');
    @include icon-size($size);
    @include icon-color($color);
}

@each $type in $icon-types {
    .i--#{$type} {
        @include icon-type($type, 'icon_')
    }
}

// - change name on S3
@each $type in $icon-types-picto {
    .i--picto-#{$type} {
        // @include icon-type($type, 'picto_')
        @include icon-type-picto($type, 'picto_')
    }
}

@each $key, $color in $icons-color {
    .i--#{$key} {
        @include icon-color($key)
    }
}

@each $key, $size in $icons-size {    
    .i--#{$key} {
        @include icon-size($key);
    }
}

//------------------------------------------------------
// - COUNTRIES
//------------------------------------------------------

$icon-countries: (FR, US, CA, BE, UK, BR, MX);

.icon--bg {
    background-size: cover;
}

@each $country in $icon-countries {
    .i--#{$country} {
        background-image: url(#{$cdn-flags}flag_#{$country}.png);
    }
}

//------------------------------------------------------
// - MY PETS
//------------------------------------------------------

.thumbnail {
    position: absolute;
    top: -10px;
    right: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #3bb292;
    border-radius: 50%;
    
    &.invalid {
        background-color: gray;
    }
}
