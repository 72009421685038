// @use "../abstracts/mixins" as *;

.d-flex {
    display: flex;
}

@include tablet {
    // - use display flex only on Tablet and Desktop
    .d-t-flex {
        display: flex;
        flex-direction: row;
    }
}

.d-flex,
.d-t-flex {
  // place on the top (vertical axis)
  &.vtop {
    align-items: flex-start;
  }

  // center vertically
  &.vcenter {
    align-items: center;
  }

  // place at the bottom (vertical axis)
  &.vbottom {
    align-items: flex-end;
  }

  // center horizontally
  &.hcenter {
    justify-content: center;
  }

  // place to the right (horizontal axis)
  &.hright {
    justify-content: flex-end;
  }

  &.hbetween {
    justify-content: space-between;
  }

  &.d-flex--wrap {
    flex-wrap: wrap;
  }

  &.is-1 {
    gap: 1rem;
  }
  &.is-2 {
    gap: 2rem;
  }
}

/*
simple class to create multiple rows
of object with fixed width
(use instead of cols that use width in percentage)
*/
.d-flex-col {
    display: flex;
    flex-direction: column;
  
    &.col-center {
      align-items: center;
    }
}

/*
to prevent flex-items to shrink if they have a fixed width
*/
.no-shrink {
    flex-shrink: 0;
}
  
.grow {
  flex-grow: 1;
  min-width: 0;
}
  
.align-self--end {
  align-self: flex-end;
}