//@import "/src/assets/style-core/abstracts/mixins-core";
@use "/src/assets/style-core/abstracts/mixins-core" as m;
// @mixin heading($fs, $color: $heading-color-default) {
//     font-size: $fs;
//     color: $color;
  
//     font-family: $heading-font-family;
//     text-transform: $heading-text-transform;
//     letter-spacing: $heading-letter-spacing;
//     font-weight: $heading-font-weight;
//     line-height: $heading-line-height;
// }


    // .coucou {
    //     padding: 0 4.3%;
    // }




@mixin mobileWrapperPadding() {
    @include m.mobile {
        padding: 0 4.3%;
    }
}

