
$input-height: 48px;

.mat-mdc-form-field {
    width: 100%;
    letter-spacing: 0 !important;
}

.mat-mdc-form-field .mdc-text-field__input {
    // padding: 12px;
}

// - Rounded border shape
.mdc-text-field--outlined {
    height: $input-height !important;
    color: $color-neutral-sand !important;
    border-color: #bbb4a7;
    --mdc-outlined-text-field-container-shape: 28px !important;
}



.mat-mdc-form-field-flex {
    height: $input-height;
}

// - Size input
.mat-mdc-form-field-infix {
    min-height: $input-height;
}

// - Input padding
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
}

// - Margin above input
.mat-mdc-text-field-wrapper {
    margin-top: .3rem;
}


.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
    border-width: 1px !important;
    border-color: #bbb4a7;
 }

.mat-mdc-form-field .mdc-text-field__input {
    // padding: 10px;
}

// - MAT ICON
.mat-mdc-icon-button.mat-mdc-button-base {

}

/**
 * - PLaceholder input
 */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
//     font-size: 16px;
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: -0.32px;
    letter-spacing: 0 !important;
    color: #554f44 !important

}
// - Icon inside Input
// .mat-mdc-icon-button {
//     height: $input-height !important;
//     width: $input-height !important;
//     //padding: 10px !important;
// }

.mdc-text-field--outlined {
    // padding: 0 .75em 0 .75em !important;
}

// .form__label {
//     font-family: $ff-primary;
//     font-weight: bold;
//     font-size: 1rem;
//     letter-spacing: -0.38px;
//     color: $color-neutral-dc;
//     text-align: left;
//     @include tablet {
//       font-size: 1.2rem;
//     }
// }

.form__required {
    font-weight: normal;
    color: #3bb292;
}

// .mat-form-field-appearance-outline .mat-form-field-outline {
//     color: #554f44; //#bbb4a7;
//   }
  
//   .mat-form-field-outline-start  {
//     border-radius: 24px 0 0 24px !important;
//     min-width: 24px !important;
//   }
  
//   .mat-form-field-outline-end {
//     border-radius: 0 24px 24px 0 !important;
//   }
//   .mat-form-field-label-wrapper {
//     display: none !important;
//   }
  
//   .mat-form-field-infix {
//     border-top: none !important;
//   }

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    background-color: white;
    padding: 5px 0;
}