
/* ----------------------------------------------
| - Default Pixel Size : 16px
------------------------------------------------*/

$breakpoints: (
  s-mobile: 20em,       // 320px 
  l-mobile: 37.5em,     // 600px
  tablet: 48.0625em,    // 769px
  desktop: 60em,        // 960px
  widescreen: 75em,     // 1200px
  xl-widescreen: 87.5em // 1400px
);