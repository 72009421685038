

    .mat-mdc-standard-chip {}

    //.chip-explorer

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: white !important;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2) !important;
    }

    .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
    .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
        background-color: #3bb292 !important;
        color: #fff !important;

        .mdc-evolution-chip__text-label {
            color: #fff !important;
        }
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
        color: var(--mdc-chip-label-text-color);
    }

    .mat-mdc-standard-chip .mdc-evolution-chip__text-label {
        font-family: $ff-primary !important;
        font-size: 1rem !important;
        font-weight: $fw-500 !important;
        line-height: 1.81 !important;
        letter-spacing: -0.32px !important;
        // font-family: var(--mdc-chip-label-text-font);
        // line-height: var(--mdc-chip-label-text-line-height);
        // font-size: var(--mdc-chip-label-text-size);
        // font-weight: var(--mdc-chip-label-text-weight);
        // letter-spacing: var(--mdc-chip-label-text-tracking);
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__checkmark {
        color: white !important;
    }