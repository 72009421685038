@use "sass:string";

/*
* - HELPERS MARGINS & PADDINGS
*
* This .scss loop will create "margin helpers" and "padding helpers" classes
*
* - m[l,r,t,b]-[0-15] = margin [left,right,top,bottom] [0-15 * .25]rem mt-2
* - example: .ml-2 = margin-left: 0.5rem
* - p[l,r,t,b]-[0-15] = padding [left,right,top,bottom] [0-15 * .25]rem pt-2
* - example: .pl-2 = padding-left: 0.5rem
* 1 : 4px (0.25rem)
* 2 : 8px (0.5rem)
* 3 : 12px (0.75rem)
* 4 : 16px (1rem)
* 5 : 20px (1.25rem)
* 6 : 24px (1.5rem)
* 7 : 28px (1.75rem)
* 8 : 32px (2rem)
* 9 : 36px (2.25rem)
* 10 : 40px (2.5rem)
* 11 : 44px (2.75rem)   
* ...
*/

$multiplier: 0.25;
$maxHelpers: 15;
$corners: (top, bottom, left, right);

@for $i from 0 through $maxHelpers {

    $size: calc($i * $multiplier);

    @each $corner in $corners {
        $firstLetter: str-slice($corner, 0, 1);

        .m#{$firstLetter}-#{$i} {
            margin-#{$corner}: #{$size}rem !important;
        }

        .p#{$firstLetter}-#{$i} {
            padding-#{$corner}: #{$size}rem !important;
        }
    }
}

@each $corner in $corners {

    $firstLetter: str-slice($corner, 0, 1);

    .m#{$firstLetter}-auto {
        margin-#{$firstLetter}: auto;
    }

    .p#{$firstLetter}-auto {
        padding-#{$firstLetter}: auto;
    }
}