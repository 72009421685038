.mat-mdc-form-field .mat-mdc-select {
    // padding: 10px !important
}

.mat-mdc-select-panel {
    background-color: white !important;
}

.mat-mdc-select { 
    letter-spacing: 0 !important;
}