
$size-circle: 22px;
$size-switch: 25px;
$width-toggle: 45px;

.mdc-switch {
    width: $width-toggle !important;
}

.mdc-switch__track::before, .mdc-switch__track::after {
    border: 1px solid rgba(0, 0, 0, 0);
}

.mdc-switch:enabled .mdc-switch__track::after {
    background-color: #3bb292 !important;
}

.mdc-switch .mdc-switch__track {
    height: $size-switch !important;
    border-radius: $size-switch !important;
}

// Circle
.mdc-switch .mdc-switch__handle {
    width: $size-circle !important;
    border-radius: 50% !important;
    background-color: white !important;
}

.mdc-switch:enabled .mdc-switch__shadow {
    background-color: white !important;
}

.mdc-switch .mdc-switch__focus-ring-wrapper, .mdc-switch .mdc-switch__handle {
    height: $size-circle !important;
}

.mdc-switch .mdc-switch__handle-track {
    width: calc(100% - 23px) !important;
}