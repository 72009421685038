.link {
    font-size: 1rem;
    // color: $color-primary-400;
    color: #2c7562;
    text-decoration: underline;
}


a.alone {
    font-size: inherit;
    color: $color-primary-400;
    cursor: pointer;
    transition: color 300ms ease;
    // -webkit-font-smoothing: antialiased
  
    &:hover {
      color: $color-secondary-400,
    }
  
    &:active,
    &:focus {
      color: $color-secondary-400,
    }
  }
  