$app-width-max: 960px;

.app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh; // IE11 fix for sticky footer
	min-height: 100vh;
}

.app-outlet-wrapper {
    flex: 1 1 auto; // to create sticky footer
    // min-height: 100vh;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: $app-width-max;
    padding: 0 4.3%;
    @include desktop {
        max-width: 960px;
        padding: 0;
    }
}

.wrapper--full {
    max-width: 100%;
    padding: 0;
}

.page {
    padding-top: 7.188rem;
    padding-bottom: 3.33rem;
    @include tablet {
        padding-top: 8.125rem;
    }
    &--sticky {
        @include mobile {
            padding-top: 5.5rem;
        }
    }
}

.column {
    padding: 0.10rem .75rem;
    @include tablet {
        padding: 0.30rem .75rem;
    }
}

.page__title {
    margin-bottom: 2rem;
    font-size: 2.25rem;
    color: $color-neutral-dc;
    font-weight: $fw-700;
    text-align: center;

    @include tablet {
        line-height: 1em;
        font-size: 2.5rem;
        margin-bottom: 1.25rem;
    }

    &--subtitle {
        margin-bottom: 0;
    }
}

.page__intro {
    margin: 2.22rem auto;
    margin-bottom: 40px;
    font-size: 1rem;
    // color: $text-semidark;
    text-align: center;
    color: #2c7562;
    max-width: 800px;
    font-family: $ff-primary;
  
    @include tablet {
      font-size: 1.25rem;
    }
  
    &--grey {
      color: #554f44;
    }
  }