.dialog__close {
    
}

.cdk-overlay-pane {
    background-color: transparent;
}

.cdk-overlay-pane.explorer-dialog {
    max-width: 100vw !important;
  
    @include tablet {
        max-width: 30vw !important;
    }
    @include mobile {
        height: 100vh !important;
    }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 10px !important;
}

//-----------------------
// - DIALOG WITH HEADER
//-----------------------

.dialog-header {
    .mat-mdc-dialog-container .mat-mdc-dialog-content {
        padding: 0;
    }
}

//-----------------------
// - DIALOG NO MATERIAL
//-----------------------
.dialog__header {
    display: flex;
    width: 100%;
    height: 200px;
    padding: 20px;
    background-image: linear-gradient(to bottom, #fff, #d9faf3);
    object-fit: contain;
    justify-content: center;
}

.dialog__header img {
    width: auto;
}

.dialog__content {
    padding: 20px;
    letter-spacing: 0;
}